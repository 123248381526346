import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

// import galleryGenerate from '../components/galleryPictures'

import Layout from '../components/layout'
import Head from '../components/head'

import bodyStyles from '../sass/components/body.module.scss'
import locationListStyles from '../sass/pages/locationlist.module.scss'
import commonStyles from '../sass/components/common.module.scss'
import sunIconSvg from '../assets/icons/vcd-sun-color.svg'
import houseSearchIconSvg from '../assets/icons/vcd-house-search-color.svg'
import swimmingPoolIconSvg from '../assets/icons/vcd-swimming-pool-color.svg'
// import { element } from 'prop-types'

const bS = bodyStyles
const llS = locationListStyles
const cmS = commonStyles

const sunIconAlt =
  'Soleil de Provence, site location de villas de vacances Varcamdobb.'
const houseSearchIconAlt =
  'Réservation de location de vacances, site location de villas de vacances Varcamdobb.'
const swimmingPoolIconAlt =
  'Location de vacances avec piscine, site location de villas de vacances Varcamdobb.'

// let cl = (...arg) => console.log(...arg)

const LocationList = () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        locations {
          edges {
            node {
              title
              slug
              locationAcf {
                picture {
                  sourceUrl
                  altText
                  title
                }
                orderOfAppearance
              }
            }
          }
        }
      }
    }
  `)

  // const galleryOptions = galleryGenerate(imgStyle)

  const document = {
    nodeType: 'document',
    content: [
      {
        nodeType: 'embedded-entry-block',
        content: [
          // why do I have to use content ?
          {
            nodeType: 'text',
            value: '',
            marks: [
              {
                type: '',
              },
            ],
          },
        ],
      },
    ],
  }

  let options = []
  let orderOfAppArray = []

  data.wpgraphql.locations.edges.forEach( (edge, index) => {

    options.push({
    
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: () => {
          const alt = edge.node.locationAcf.picture.altText
          const imageurl = edge.node.locationAcf.picture.sourceUrl
          return <img className={llS.box} alt={alt} src={imageurl} />
        },
      },
    })

    orderOfAppArray.push(edge.node.locationAcf.orderOfAppearance) 
  })

  // Sort the "options" array based on "orderOfApp" elements
    // build an array containing "options" and corresponding order of appearance
  let arrayIndexed = options.map((inputElement, index) => {
    const outputElement = {
      inputElement,
      "index": orderOfAppArray[index]
    }
    return outputElement
  })
    // sort upon order of appearance
  arrayIndexed.sort((a, b) => a.index - b.index).map((arrayIndexed, index, array) => arrayIndexed.name)

    // remap to "options" array
  options = arrayIndexed.map( (element, index) => arrayIndexed[index]['inputElement'])

  // Sort the "data.wpgraphql.locations.edges" array based on "orderOfApp" elements
      // build an array containing "--edges" and corresponding order of appearance
  arrayIndexed = data.wpgraphql.locations.edges.map((inputElement, index) => {
    const outputElement = {
      inputElement,
      "index": orderOfAppArray[index]
    }
    return outputElement
  })
    // sort upon order of appearance
  arrayIndexed.sort((a, b) => a.index - b.index).map((arrayIndexed, index, array) => arrayIndexed.name)

    // remap to "options" array
  const edges = arrayIndexed.map( (element, index) => arrayIndexed[index]['inputElement'])


  return (
    <Layout>
      <Head title={'Locations'} />

      <div className={bS.container}>
          <h1 className={cmS.title}> Villa, gîte-appart ou cabane insolite </h1>
          <div className={cmS.iconWrapperWrapper}>      
          <div className={cmS.iconWrapper}>
            <img className={cmS.box3Icon} src={sunIconSvg} alt={sunIconAlt} />
            <img
              className={cmS.box1Icon}
              src={swimmingPoolIconSvg}
              alt={swimmingPoolIconAlt}
            />
            <img
              className={cmS.box2Icon}
              src={houseSearchIconSvg}
              alt={houseSearchIconAlt}
            />
          </div>
        </div>

        <div className={cmS.mainComponentWrapper}>
          <h2 className={cmS.subtitle}>
            Et si nous commencions à parler de vos vacances...
          </h2>
          <div className={llS.paragraphWrapper}>
            <div className={llS.paragraphBox}>
              <div className={cmS.paragraph}>
                <p>
                  Réservez maintenant votre villa, gîte-appartement,
                  chalet-villa, ou cabane insolite préféré(e) !
                </p>
              </div>
            </div>
            <div className={llS.paragraphBox}>
              <div className={cmS.paragraph}>
                <p>
                  Vous pouvez nous joindre par le moyen qui vous convient le mieux sur <Link className={llS.email} to="/contact">
                    cette page
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={cmS.mainComponentWrapper}>
          <h2 className={cmS.subtitle}> Votre location chez Varcamdobb </h2>

          <div className={llS.galleryContainer}>
            <div className={llS.gridContainer}>
              {edges.map((edge, index) => (
                <li className={llS.locationList} key={edge.node.slug}>
                  <div className={eval(`llS.box${index + 1}`)}>
                    {documentToReactComponents(document, options[index])}
                  </div>
                  <div className={llS.locationItemWrapperWrapper}>
                    <div className={llS.locationItemWrapper}>
                      <Link
                        className={llS.locationItem}
                        activeClassName={llS.activeLocationItemWrapper}
                        to={`/location/${edge.node.slug}`}
                      >
                        <h3 className={llS.locationTitle}>{edge.node.title}</h3>
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LocationList
